<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "SidenavItem",
  props: {
    to: {
      type: String,
      required: true,
    },
    navText: {
      type: String,
      required: true,
    },
  },
  setup() {
    const store = useStore();

    const isRTL = computed(() => store.state.isRTL);

    const sidebarMinimize = () => store.commit("sidebarMinimize");

    const minimizeSidebar = () => {
      if (window.innerWidth < 1200) {
        sidebarMinimize();
      }
    };

    return {
      isRTL,
      minimizeSidebar,
    };
  },
};
</script>

<template>
  <router-link :to="to" class="nav-link" @click="minimizeSidebar">
    <div
      class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center"
    >
      <slot name="icon"></slot>
    </div>
    <span class="nav-link-text" :class="isRTL ? ' me-1' : 'ms-1'">{{
      navText
    }}</span>
  </router-link>
</template>
