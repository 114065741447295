<script>
export default {
  name: "MiniStatisticsCard",
  props: {
    rowReverse: {
      type: Boolean,
      default: false,
    },
    title: {
      type: [Object, String],
      required: true,
      default: () => ({
        text: "",
        color: "",
      }),
    },
    description: {
      type: String,
      default: "",
    },
    value: {
      type: [Object, String, Number],
      required: true,
      default: () => ({
        text: "",
        color: "",
      }),
    },
    percentage: {
      type: [Object, String],
      default: () => ({
        color: "success",
        value: "",
      }),
    },
    icon: {
      type: [String, Object],
      default: () => ({
        background: "bg-white",
        shape: "border-radius-md",
        component: "",
      }),
    },
    classContent: {
      type: String,
      default: "",
    },
  },
  computed: {
    titleText() {
      return typeof this.title === "string" ? this.title : this.title.text;
    },
    titleColor() {
      return typeof this.title === "object" ? this.title.color : "";
    },
    valueText() {
      if (typeof this.value === "string" || typeof this.value === "number") {
        return this.value;
      }
      return this.value.text;
    },
    valueColor() {
      return typeof this.value === "object" ? this.value.color : "";
    },
    percentageValue() {
      return typeof this.percentage === "string" ? this.percentage : this.percentage.value;
    },
    percentageColor() {
      return typeof this.percentage === "object" ? `text-${this.percentage.color}` : "";
    },
    iconComponent() {
      return typeof this.icon === "object" ? this.icon.component : this.icon;
    },
    iconClasses() {
      return typeof this.icon === "object"
        ? `${this.icon.background} ${this.icon.shape}`
        : "border-radius-md";
    },
  },
};
</script>

<template>
  <div class="mb-3 card">
    <div class="p-3 card-body">
      <div
        class="d-flex"
        :class="rowReverse ? '' : 'flex-row-reverse justify-content-between'"
      >
        <div
          class="text-center shadow icon icon-shape"
          :class="[iconClasses, rowReverse ? 'me-2' : '']"
        >
          <i
            class="text-lg opacity-10"
            :class="iconComponent"
            aria-hidden="true"
          ></i>
        </div>
        <div :class="classContent">
          <div class="numbers">
            <p
              class="mb-0 text-sm text-uppercase font-weight-bold"
              :class="titleColor"
            >
              {{ titleText }}
            </p>
            <h5 :class="`mb-0 font-weight-bolder ${valueColor}`">
              {{ valueText }}
              <span
                v-if="typeof percentage === 'string'"
                class="text-sm font-weight-bolder"
              >
                {{ percentageValue }}
              </span>
              <span
                v-if="typeof percentage === 'object'"
                :class="`text-sm font-weight-bolder ${percentageColor}`"
              >
                {{ percentageValue }}
              </span>
            </h5>
            <p v-if="description" class="mt-2 mb-0" v-html="description"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
