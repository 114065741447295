<script>
import { Chart } from "chart.js/auto";

export default {
  name: "GradientLineChart",
  props: {
    id: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      default: "300",
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    chart: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.initializeChart();
  },
  methods: {
    initializeChart() {
      const gradientLineChart = document
        .getElementById(this.id)
        .getContext("2d");

      const gradientStroke1 = gradientLineChart.createLinearGradient(
        0,
        230,
        0,
        50
      );
      gradientStroke1.addColorStop(1, "rgba(203,12,159,0.2)");
      gradientStroke1.addColorStop(0.2, "rgba(72,72,176,0.0)");
      gradientStroke1.addColorStop(0, "rgba(203,12,159,0)");

      const gradientStroke2 = gradientLineChart.createLinearGradient(
        0,
        230,
        0,
        50
      );
      gradientStroke2.addColorStop(1, "rgba(20,23,39,0.2)");
      gradientStroke2.addColorStop(0.2, "rgba(72,72,176,0.0)");
      gradientStroke2.addColorStop(0, "rgba(20,23,39,0)");

      // Destroy existing chart instance if it exists
      const existingChart = Chart.getChart(this.id);
      if (existingChart) existingChart.destroy();

      const datasets = this.chart.datasets.map((dataset, index) => ({
        label: dataset.label,
        tension: 0.4,
        borderWidth: 3,
        pointRadius: 0,
        borderColor: index === 0 ? "#4BB543" : "#3A416F",
        backgroundColor: index === 0 ? gradientStroke1 : gradientStroke2,
        fill: true,
        data: dataset.data,
        maxBarThickness: 6,
      }));

      new Chart(gradientLineChart, {
        type: "line",
        data: {
          labels: this.chart.labels,
          datasets,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                padding: 10,
                color: "#fbfbfb",
                font: {
                  size: 11,
                  family: "Open Sans",
                  style: "normal",
                  lineHeight: 2,
                },
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                color: "#ccc",
                padding: 20,
                font: {
                  size: 11,
                  family: "Open Sans",
                  style: "normal",
                  lineHeight: 2,
                },
              },
            },
          },
        },
      });
    },
  },
};
</script>

<template>
  <div class="card z-index-2">
    <div class="pb-0 card-header mb-0">
      <h6>{{ title }}</h6>
      <p v-if="description" class="text-sm" v-html="description"></p>
    </div>
    <div class="p-3 card-body">
      <div class="chart">
        <canvas :id="id" class="chart-canvas" :height="height"></canvas>
      </div>
    </div>
  </div>
</template>
