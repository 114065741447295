import { createRouter, createWebHashHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Clients from "../views/Clients.vue";
import Providers from "../views/Providers.vue";
import Profile from "../views/Profile.vue";
import Signin from "../views/Signin.vue";
import store from "../store/index";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/signin",
  },
  {
    path: "/dashboard-default",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: "/clients",
    name: "Clients",
    component: Clients,
    meta: { requiresAuth: true },
  },
  {
    path: "/provider",
    name: "Providers",
    component: Providers,
    meta: { requiresAuth: true },
  },

  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: { requiresAuth: true },
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

// router/index.js
// router.beforeEach((to, from, next) => {
//   const isAuthenticated = store.getters["auth/isAuthenticated"];

//   if (to.matched.some((record) => record.meta.requiresAuth)) {
//     if (!isAuthenticated) {
//       next({ path: "/signin" });
//     } else {
//       next();
//     }
//   } else if (to.path === "/signin" && isAuthenticated) {
//     next({ path: "/dashboard-default" });
//   } else {
//     next();
//   }
// });
// router/index.js
router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters["auth/isAuthenticated"];
  
  if (to.meta.requiresAuth && !isAuthenticated) {
    next("/signin"); // Redirect unauthenticated to sign-in page
  } else if (to.path === "/signin" && isAuthenticated) {
    next("/dashboard-default"); // Redirect authenticated users to dashboard
  } else {
    next(); // Proceed normally
  }
})


export default router;
