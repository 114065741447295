import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import ArgonDashboard from "./argon-dashboard";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
// import 'bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';

const appInstance = createApp(App);
store.dispatch("auth/checkAuth");

const options = {
  position: "top-center",
  timeout: 3000,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

appInstance.use(store);
appInstance.use(router);
appInstance.use(ArgonDashboard);
appInstance.use(Toast, options);
appInstance.mount("#app");
