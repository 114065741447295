<script>
import { onBeforeUnmount, onBeforeMount, ref, computed } from "vue";
import { useStore } from "vuex";
import Navbar from "@/examples/PageLayout/Navbar.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";
// import UAParser from 'ua-parser-js';

export default {
  name: "SignInPage",
  components: {
    Navbar,
    ArgonInput,
    ArgonButton,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const body = document.getElementsByTagName("body")[0];

    // const user = computed(()=>store.getters['login/getUser'])

    // watch(user, (newUser)=>{
    //   if(newUser){

    //     console.log('After Login state user', newUser)
    //   }
    // })

    onBeforeMount(() => {
      store.state.hideConfigButton = true;
      store.state.showNavbar = false;
      store.state.showSidenav = false;
      store.state.showFooter = false;
      body.classList.remove("bg-gray-100");
    });

    onBeforeUnmount(() => {
      store.state.hideConfigButton = false;
      store.state.showNavbar = true;
      store.state.showSidenav = true;
      store.state.showFooter = true;
      body.classList.add("bg-gray-100");
    });

    const phoneNumber = ref("");
    const password = ref("");

    const isLoading = computed(() => store.state.login.loading);
    const errorMessage = computed(() => store.state.login.error);
    // const loginStatus = computed(() => store.state.login.loginStatus);
    const validateForm = () => {
      return phoneNumber.value && password.value;
    };

    // const getUserDeviceInfo = () => {
    //   const parser = new UAParser();

    //   const browserInfo = parser.getBrowser();
    //   const engineInfo = parser.getEngine();
    //   const deviceInfo = parser.getDevice();
    //   const osInfo = parser.getOS();

    //   return {
    //     browser: browserInfo.name || "",
    //     engine: engineInfo.name || "",
    //     device_info: `${deviceInfo.vendor || ""} ${deviceInfo.model || ""} ${
    //       deviceInfo.type || ""
    //     }`.trim(),
    //     version_info: osInfo.version || "",
    //   };
    // };

    const LoginForm = async () => {
      const toast = useToast();
      if (!validateForm()) {
        toast.warning("Please Fill all Fields");
        return;
      } else if (password.value.length <= 4) {
        toast.warning("Password must be greater than 4 characters");
        return;
      }
      // else if (phoneNumber.value.length !== 10) {
      //   toast.warning("Phone Number must be 10 characters");
      //   return;
      // }

      // const deviceDetails = getUserDeviceInfo();

      const userData = {
        // browser: deviceDetails.browser,
        // campaign: "string",
        // channel_id: 0,
        // device_info: deviceDetails.device_info,
        // engine: deviceDetails.engine,
        // medium: "string",
        email: "string",
        msisdn: parseInt(phoneNumber.value, 10),
        password: password.value,
        username: "string",
        // referral_code: "string",
        // referrer: "string",
        // source: "string",
        // version_info: deviceDetails.version_info,
      };

      // console.log("userdata", userData);

      await store.dispatch("login/Login", userData);

      // if (store.state.login.loginStatus === "success") {
      //   toast.success("login Successful, Redirecting to Dashboard..");

      //   setTimeout(() => {
      //     router.push("/dashboard-default");
      //     this.$router.push('/dashboard-default');
      //   }, 1000);
      // } else {
      //   toast.error(errorMessage.value);
      // }

      // if (loginStatus.value === "failed") {
      //   toast.error(errorMessage.value);
      // }
      if (store.state.login.loginStatus === "success") {
        toast.success("Login Successful, Redirecting to Dashboard...");
        setTimeout(() => {
          router.push("/dashboard-default");
        }, 1000);
      } else {
        toast.error(errorMessage.value);
      }
    };

    // watch(loginStatus, (newStatus) => {
    //   if (newStatus === "success") {
    //     const toast = useToast();
    //     toast.success("Login successful, redirecting to Dashboard...");
    //     router.push("/dashboard-default");
    //   }
    // });

    return {
      phoneNumber,
      password,
      isLoading,
      LoginForm,
    };
  },
};
</script>

<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          :darkMode="true"
          isBtn="bg-gradient-success"
        />
      </div>
    </div>
  </div>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div
              class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0"
            >
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">Login</h4>
                </div>
                <div class="card-body">
                  <form role="form" @submit.prevent="LoginForm">
                    <div class="mb-3">
                      <argon-input
                        v-model="phoneNumber"
                        id="msisdn"
                        type="text"
                        placeholder="Phone Number eg.07xxxxxxx"
                        name="phonenumber"
                        size="lg"
                        :disabled="isLoading"
                      />
                    </div>
                    <div class="mb-3">
                      <argon-input
                        v-model="password"
                        id="password"
                        type="password"
                        placeholder="Password"
                        name="password"
                        size="lg"
                        :isRequired="true"
                        :disabled="isLoading"
                      />
                    </div>
                    <!-- <argon-switch id="rememberMe" name="remember-me">
                      Remember me
                    </argon-switch> -->
                    <div class="text-center">
                      <argon-button
                        class="mt-4"
                        variant="gradient"
                        color="success"
                        fullWidth
                        size="lg"
                        :disabled="isLoading"
                      >
                        <span v-if="isLoading">Loading...</span>
                        <span v-else>Login</span>
                      </argon-button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            >
              <div
                class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                style="
                  background-image: url(&quot;https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-ill.jpg&quot;);
                  background-size: cover;
                "
              >
                <span class="mask bg-gradient-success opacity-6"></span>
                <h4
                  class="mt-5 text-white font-weight-bolder position-relative"
                >
                  Casino Manager
                </h4>
                <p class="text-white position-relative">
                  Where every decision is a chance to win big!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
