<script>
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import SidenavItem from "./SidenavItem.vue";

export default {
  name: "SidenavList",
  components: {
    SidenavItem,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    // const router = useRouter();
    const toast = useToast();
    const isRTL = computed(() => store.state.isRTL);

    const getRoute = () => {
      const routeArr = route.path.split("/");
      // console.log(routeArr[1]);
      return routeArr[1];
    };

    const handleLogout = async () => {
      await store.dispatch("login/LogoutUser");
      toast.info("LogOut Successfully");
      setTimeout(() => {
        // window.location.href = "/";
        // router.replace("/signin");
        // router.push("/signin");
        window.location.reload();
      }, 1000);
      // this.$router.push("/signin");
    };

    return {
      isRTL,
      getRoute,
      handleLogout,
    };
  },
};
</script>

<template>
  <div
    class="collapse navbar-collapse w-auto h-auto h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-item
          to="/dashboard-default"
          :class="getRoute() === 'dashboard-default' ? 'active' : ''"
          :navText="isRTL ? 'لوحة القيادة' : 'Dashboard'"
        >
          <template v-slot:icon>
            <i class="ni ni-tv-2 text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/clients"
          :class="getRoute() === 'clients' ? 'active' : ''"
          :navText="isRTL ? 'الجداول' : 'Clients'"
        >
          <template v-slot:icon>
            <i
              class="ni ni-calendar-grid-58 text-warning text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/provider"
          :class="getRoute() === 'provider' ? 'active' : ''"
          :navText="isRTL ? 'الفواتیر' : 'Providers'"
        >
          <template v-slot:icon>
            <i class="ni ni-credit-card text-success text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="mt-3 nav-item">
        <h6
          v-if="isRTL"
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="isRTL ? 'me-4' : 'ms-2'"
        >
          صفحات المرافق
        </h6>

        <h6
          v-else
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="isRTL ? 'me-4' : 'ms-2'"
        >
          ACCOUNT PAGES
        </h6>
      </li>

      <li class="nav-item">
        <sidenav-item
          to="/profile"
          :class="getRoute() === 'profile' ? 'active' : ''"
          :navText="isRTL ? 'حساب تعريفي' : 'Profile'"
        >
          <template v-slot:icon>
            <i class="ni ni-single-02 text-dark text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <div class="nav-link cursor-pointer" @click="handleLogout">
          <div
            class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center"
          >
            <i class="ni ni-single-copy-04 text-danger text-sm opacity-10"></i>
          </div>
          <span class="nav-link-text" :class="isRTL ? ' me-1' : 'ms-1'">
            {{ isRTL ? "تسجيل الدخول" : "Logout" }}
          </span>
        </div>
      </li>
    </ul>
  </div>
</template>
