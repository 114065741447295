<template>
  <div class="card">
    <div class="card-header pb-0">
      <h6>Clients table</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div style="margin-left: 15px">
        <button class="btn btn-primary" @click="showCreateModal = true">
          Create CLient
        </button>
      </div>

      <div
        class="modal"
        tabindex="-1"
        role="dialog"
        v-if="showCreateModal"
        :style="{
          display: showCreateModal ? 'block' : 'none',
          background: 'rgba(0, 0, 0, 0.5)',
        }"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="title" id="largeModalLabel">Create Client</h4>
              <hr />
            </div>
            <div class="modal-body">
              <div class="body">
                <form id="form_validation" @submit.prevent="createClient">
                  <div class="form-group form-float">
                    <label class="fw-semibold">Name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Client Name"
                      name="name"
                      required
                      v-model="client_name"
                    />
                  </div>

                  <div class="form-group form-float">
                    <label class="fw-semibold">Authentication Header</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Authentication Header"
                      name="name"
                      required
                      v-model="auth_header"
                    />
                  </div>
                  <div class="form-group form-float">
                    <label class="fw-semibold">Authentication String</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Authentication String"
                      name="name"
                      required
                      v-model="auth_string"
                    />
                  </div>
                  <div class="form-group form-float">
                    <label class="fw-semibold">Base URL</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Base URL"
                      name="name"
                      required
                      v-model="base_url"
                    />
                  </div>

                  <button
                    class="btn btn-raised btn-primary waves-effect"
                    type="submit"
                  >
                    <span v-if="loading">Loading...</span>
                    <span v-else>SUBMIT</span>
                  </button>
                </form>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-danger waves-effect"
                @click="showCreateModal = false"
              >
                CLOSE
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- MODAL FOR UPDATING CLIENT -->

      <div
        class="modal"
        tabindex="-1"
        role="dialog"
        v-if="showUpdateModal"
        :style="{
          display: showUpdateModal ? 'block' : 'none',
          background: 'rgba(0, 0, 0, 0.5)',
        }"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="title" id="largeModalLabel">
                Update {{ this.client_name ? this.client_name : "N/A" }}
              </h4>
              <hr />
            </div>
            <div class="modal-body">
              <div class="body">
                <form id="form_validation" @submit.prevent="updateClient">
                  <div class="form-group form-float">
                    <label class="fw-semibold">Name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Client Name"
                      name="name"
                      v-model="client_name"
                    />
                    <!-- required  -->
                  </div>

                  <div class="form-group form-float">
                    <label class="fw-semibold">Authentication Header</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Authentication Header"
                      name="name"
                      v-model="auth_header"
                    />
                    <!-- required -->
                  </div>
                  <div class="form-group form-float">
                    <label class="fw-semibold">Authentication String</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Authentication String"
                      name="name"
                      v-model="auth_string"
                    />
                    <!-- required -->
                  </div>
                  <div class="form-group form-float">
                    <label class="fw-semibold">Base URL</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Base URL"
                      name="name"
                      v-model="base_url"
                    />
                    <!-- required -->
                  </div>

                  <button
                    class="btn btn-raised btn-primary waves-effect"
                    type="submit"
                  >
                    <span v-if="loading">Loading...</span>
                    <span v-else>UPDATE</span>
                  </button>
                </form>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-danger waves-effect"
                @click="showUpdateModal = false"
              >
                CLOSE
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- MODAL FOR DELETING A CLIENT -->

      <div
        class="modal"
        tabindex="-1"
        role="dialog"
        v-if="showDeleteModal"
        :style="{
          display: showDeleteModal ? 'block' : 'none',
          background: 'rgba(0, 0, 0, 0.5)',
        }"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="title" id="largeModalLabel">
                Are you sure you want to Delete
                {{ this.client_name ? this.client_name : "N/A" }}
              </h4>
              <hr />
            </div>
            <div class="modal-body">
              <div class="body">
                <button
                  class="btn btn-raised btn-danger waves-effect"
                  type="submit"
                  @click.prevent="deleteClient"
                >
                  <span v-if="loading">Loading...</span>
                  <span v-else>DELETE</span>
                </button>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-danger waves-effect"
                @click="showDeleteModal = false"
              >
                CLOSE
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- MODAL FOR ENABLING AND DISABLING CLIENT -->

      <div
        class="modal"
        tabindex="-1"
        role="dialog"
        v-if="showEnablerModal"
        :style="{
          display: showEnablerModal ? 'block' : 'none',
          background: 'rgba(0, 0, 0, 0.5)',
        }"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="title" id="largeModalLabel">
                <!-- Are you sure you want to
                {{ this.status == 1 ? "Disable" : "Enable" }}
                {{ this.client_name ? this.client_name : "N/A" }} -->
                Update Something Specific for
                {{ this.client_name ? this.client_name : "N/A" }}
              </h4>
              <hr />
            </div>
            <div class="modal-body">
              <div class="body">
                <form id="form_validation" @submit.prevent="enableClient">
                  <div class="form-group form-float">
                    <label class="fw-semibold">Attribute</label>
                    <select
                      class="form-control"
                      v-model="selectedAttribute"
                      name="attribute"
                      required
                    >
                      <option disabled value="">Select Attribute</option>
                      <option value="authentication_header">
                        authentication_header
                      </option>
                      <option value="authentication_string">
                        authentication_string
                      </option>
                      <option value="base_url">base_url</option>
                      <option value="name">name</option>
                      <option value="status">status</option>
                    </select>
                  </div>

                  <div class="form-group form-float">
                    <label class="fw-semibold">Value</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Value"
                      name="name"
                      required
                      v-model="value"
                    />
                  </div>

                  <button
                    class="btn btn-raised btn-primary waves-effect"
                    type="submit"
                  >
                    <span v-if="loading">Loading...</span>
                    <span v-else>UPDATE</span>
                  </button>
                </form>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-danger waves-effect"
                @click="showEnablerModal = false"
              >
                CLOSE
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0 wider-table">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Name
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Authentication Header
              </th>

              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Base URL
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                IP Address
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Status
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="loading">
              <td colspan="5" class="text-center">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </td>
            </tr>
            <tr v-else-if="clientData.length === 0">
              <td colspan="5" class="text-center">No clients found</td>
            </tr>

            <tr v-else v-for="client in clientData" :key="client.id">
              <td>
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">
                      {{ client ? client.name : "N/A" }}
                    </h6>
                    <p class="text-xs text-secondary mb-0">
                      {{ client ? client.account : "N/A" }}
                    </p>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">
                  {{ client ? client.authentication_header : "N/A" }}
                </p>
                <p class="text-xs text-secondary mb-0">
                  {{ client ? client.authentication_string : "N/A" }}
                </p>
              </td>

              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold">{{
                  client ? client.base_url : "N/A"
                }}</span>
              </td>

              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold">{{
                  client ? client.ip_address : "N/A"
                }}</span>
              </td>
              <td class="align-middle text-center text-sm">
                <span
                  class="badge badge-sm"
                  :class="client.status === 1 ? 'bg-success' : 'bg-danger'"
                >
                  {{ client.status === 1 ? "Active" : "Inactive" }}
                </span>
              </td>
              <td class="align-middle text-center text-sm">
                <span
                  class="badge badge-sm text-white bg-primary cursor-pointer"
                  @click="ToggleUpdateModal(client)"
                  >Update</span
                >

                <span
                  class="badge badge-sm text-white bg-danger cursor-pointer"
                  @click="ToggleDeleteModal(client)"
                  >Delete</span
                >

                <span
                  class="badge badge-sm text-white cursor-pointer"
                  :class="client.status === 1 ? 'bg-warning' : 'bg-success'"
                  @click="ToggleEnableModal(client)"
                >
                  {{ client.status === 1 ? "Disable" : "Enable" }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { useToast } from "vue-toastification";
import BaseURL from "../../services/Base";

export default {
  name: "ClientTable",
  data() {
    return {
      clientData: [],
      loading: false,
      showCreateModal: false,
      client_name: "",
      auth_header: "",
      auth_string: "",
      base_url: "",
      client_id: null,
      showUpdateModal: false,
      showDeleteModal: false,
      showEnablerModal: false,
      status: null,
      selectedAttribute: "",
      value: "",
    };
  },
  methods: {
    ToggleUpdateModal(client) {
      if (client) {
        this.client_name = client.name;
        this.auth_header = client.authentication_header;
        this.auth_string = client.authentication_string;
        this.base_url = client.base_url;
        this.client_id = client.id;

        this.showUpdateModal = true;
      } else {
        console.error("Client data is incomplete or invalid.");

        const toast = useToast();
        toast.warning("Cannot open update modal. Incomplete client data.");
      }
    },

    ToggleDeleteModal(client) {
      if (client) {
        this.client_id = client.id;
        this.client_name = client.name;

        this.showDeleteModal = true;
      } else {
        console.error("Client data is incomplete or invalid.");

        const toast = useToast();
        toast.warning("Cannot open Delete modal. Incomplete client data.");
      }
    },
    ToggleEnableModal(client) {
      if (client) {
        this.client_id = client.id;
        this.status = client.status;
        this.client_name = client.name;

        this.showEnablerModal = true;
      } else {
        console.error("Client data is incomplete or invalid.");

        const toast = useToast();
        toast.warning(
          "Cannot open Enable/Disable modal. Incomplete client data."
        );
      }
    },

    async FetchClients() {
      const toast = useToast();

      try {
        this.loading = true;
        const token = Cookies.get("token");
        console.log("token", token);

        const response = await BaseURL.get("client", {
          headers: {
            "Content-Type": "application/json",
            "api-key": token,
            //  Authorization: `Bearer ${token}`
          },
        });

        console.log("clients", response);

        if (response.status == 200) {
          this.clientData = response.data;
        }
      } catch (err) {
        console.log(err);
        if (err.status == 401) {
          toast.error(err.response.data.message);
          setTimeout(() => {
            this.$router.push("/signin");
          }, 1000);
        } else if (err.status == 403) {
          toast.error(err.response.data.message);
        } else if (err.status == 404) {
          toast.error(err.response.data.error_message);
        }
      } finally {
        this.loading = false;
      }
    },
    async createClient() {
      const toast = useToast();

      if (
        !this.client_name ||
        !this.auth_header ||
        !this.auth_string ||
        !this.base_url
      ) {
        toast.warning("Please Fill all details");
        return;
      }

      try {
        this.loading = true;
        const token = Cookies.get("token");

        const data = {
          name: this.client_name,
          authentication_header: this.auth_header,
          authentication_string: this.auth_string,
          base_url: this.base_url,
        };

        const response = await BaseURL.post("client", data, {
          headers: {
            "Content-Type": "application/json",
            "api-key": token,
            //  Authorization: `Bearer ${token}`
          },
        });

        console.log("client created", response);

        if (response.status == 201) {
          toast.success(response.data.data);
          this.FetchClients();
          // this.showCreateModal = false
          return;
        }
      } catch (err) {
        console.log(err);
        if (err.status == 401) {
          toast.error(err.response.data.error_message);
          setTimeout(() => {
            this.$router.push("/signin");
          }, 1000);
        } else if (err.status == 400) {
          toast.error(err.response.data.error_message);
        } else if (err.status == 417) {
          toast.error(err.response.data.error_message);
        } else if (err.status == 422) {
          toast.error(err.response.data.error_message);
        } else if (err.status == 423) {
          toast.error(err.response.data.error_message);
        }
      } finally {
        this.loading = false;
        this.showCreateModal = false;
      }
    },

    async updateClient() {
      const toast = useToast();

      try {
        this.loading = true;
        const token = Cookies.get("token");

        const data = {
          name: this.client_name,
          authentication_header: this.auth_header,
          authentication_string: this.auth_string,
          base_url: this.base_url,
        };

        const response = await BaseURL.put(`client/${this.client_id}`, data, {
          headers: {
            "Content-Type": "application/json",
            "api-key": token,
            //  Authorization: `Bearer ${token}`
          },
        });

        console.log("client Updated", response);

        if (response.status == 201) {
          toast.success("client Updated" || response.data.data);
          this.FetchClients();
          this.showUpdateModal = false;
          return;
        }
      } catch (err) {
        console.log(err);
        if (err.status == 401) {
          toast.error(err.response.data.error_message);
          setTimeout(() => {
            this.$router.push("/signin");
          }, 1000);
        } else if (err.status == 400) {
          toast.error(err.response.data.error_message);
        } else if (err.status == 417) {
          toast.error(err.response.data.error_message);
        } else if (err.status == 422) {
          toast.error(err.response.data.error_message);
        } else if (err.status == 423) {
          toast.error(err.response.data.error_message);
        }
      } finally {
        this.loading = false;
        this.showUpdateModal = false;
      }
    },

    async deleteClient() {
      const toast = useToast();

      try {
        this.loading = true;
        const token = Cookies.get("token");

        const response = await BaseURL.delete(`client/${this.client_id}`, {
          headers: {
            "Content-Type": "application/json",
            "api-key": token,
            //  Authorization: `Bearer ${token}`
          },
        });

        console.log("client deleted", response);

        if (response.status == 201) {
          toast.success(response.data.data);
          // 'client Updated' ||
          this.FetchClients();
          this.showDeleteModal = false;
          return;
        }
      } catch (err) {
        console.log(err);
        if (err.status == 401) {
          toast.error(err.response.data.message);
          setTimeout(() => {
            this.$router.push("/signin");
          }, 1000);
        } else if (err.status == 400) {
          toast.error(err.response.data.message);
        } else if (err.status == 417) {
          toast.error(err.response.data.message);
        } else if (err.status == 422) {
          toast.error(err.response.data.message);
        } else if (err.status == 423) {
          toast.error(err.response.data.message);
        }
      } finally {
        this.loading = false;
        this.showDeleteModal = false;
      }
    },

    async enableClient() {
      const toast = useToast();

      if (!this.selectedAttribute || !this.value) {
        toast.warning("Please Fill all Fields");
        return;
      }

      try {
        this.loading = true;
        const token = Cookies.get("token");
        const data = {
          field_name: this.selectedAttribute,
          value: this.value,
        };

        const response = await BaseURL.patch(`client/${this.client_id}`, data, {
          headers: {
            "Content-Type": "application/json",
            "api-key": token,
            //  Authorization: `Bearer ${token}`
          },
        });

        console.log("client value updated", response);

        if (response.status == 201) {
          toast.success(response.data.data);

          this.FetchClients();
          this.showEnablerModal = false;
          return;
        }
      } catch (err) {
        console.log(err);
        if (err.status == 401) {
          toast.error(err.response.data.message);
          setTimeout(() => {
            this.$router.push("/signin");
          }, 1000);
        } else if (err.status == 400) {
          toast.error(err.response.data.message);
        } else if (err.status == 417) {
          toast.error(err.response.data.message);
        } else if (err.status == 422) {
          toast.error(err.response.data.message);
        } else if (err.status == 423) {
          toast.error(err.response.data.message);
        }
      } finally {
        this.loading = false;
        this.showEnablerModal = false;
      }
    },
  },
  mounted() {
    this.FetchClients();
  },
};
</script>

<style scoped>
.table-responsive {
  overflow-x: auto;
}


@media (max-width: 768px) {
  .responsive-card {
    padding: 0 15px; 
  }
  .wider-table {
    min-width: 100%; 
  }
}
</style>
