import Cookies from "js-cookie";

const state = {
  token: null,
};

const getters = {
  isAuthenticated: (state) => !!state.token,
  // returns true if token exists
};

const actions = {
  checkAuth({ commit }) {
    const token = Cookies.get("token");
    // console.log(token);
    if (token) {
      commit("setToken", token);
    } else {
      commit("clearToken");
    }
  },
};

const mutations = {
  setToken(state, token) {
    state.token = token;
  },
  clearToken(state) {
    state.token = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
