import IdentityService from "../services/Auth";
import Cookies from "js-cookie";
export default {
  namespaced: true,
  state: {
    loginStatus: "",
    user: null,
    loading: false,
    error: null,
  },
  actions: {
    async Login({ commit, dispatch }, payload) {
      commit("setLoading", true);
      commit("clearError");

      try {
        const response = await IdentityService.post("/user/login", payload);
        console.log("login data", response.data.auth);

        if (
          response.status == 200 ||
          response.status == 201 ||
          response.status == 202 ||
          response.status == 204
        ) {
          Cookies.set("token", response.data.auth, { expires: 1 });
          commit("setUser", response);
          commit("setToken", response.data.auth);
          dispatch("auth/checkAuth", null, { root: true });
          commit("setLoginStatus", "success");
        }
      } catch (err) {
        console.log(err);
        commit("setLoginStatus", "failed");
        if (err.response) {
          const { status, data } = err.response;
          let errorMessage = data.error_message || "An error occurred";

          switch (status) {
            case 400:
              errorMessage = data.error_message || "Invalid Inputs";
              break;

            case 422:
              errorMessage = data.error_message || "Unprocessable Entity";
              break;

            case 500:
              errorMessage = data.error_message || "Internal Server Error";
              break;

            default:
              errorMessage = data.error_message || "An unknown error occurred";
              break;
          }
          commit("setError", errorMessage);
        } else {
          commit("setError", "Network Error. Check Your Connection");
        }
      } finally {
        commit("setLoading", false);
      }
    },
    LogoutUser({ commit, dispatch }) {
      Cookies.remove("token");
      commit("setUser", null);
      commit("setLoginStatus", "");
      commit("setError", "");
      dispatch("auth/clearToken", null, { root: true });
    },
  },
  mutations: {
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
    setUser(state, user) {
      state.user = user;
    },
    setLoginStatus(state, status) {
      state.loginStatus = status;
    },
    setError(state, error) {
      state.error = error;
    },
    setToken(state, token) {
      state.token = token;
    },
    clearError(state) {
      state.error = null;
    },
  },
  getters: {
    isLoading: (state) => state.loading,
    getUser: (state) => state.user,
    loginStatus: (state) => state.loginStatus,
    errorMessage: (state) => state.errorMessage,
  },
};
