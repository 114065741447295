<template>
  <div class="card">
    <div class="card-header pb-0">
      <h6>Providers table</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div style="margin-left: 15px">
        <button class="btn btn-primary" @click="showCreateModal = true">
          Create Provider
        </button>
      </div>

      <div
        class="modal"
        tabindex="-1"
        role="dialog"
        v-if="showCreateModal"
        :style="{
          display: showCreateModal ? 'block' : 'none',
          background: 'rgba(0, 0, 0, 0.5)',
        }"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="title" id="largeModalLabel">Create Provider</h4>
              <hr />
            </div>
            <div class="modal-body">
              <div class="body">
                <form id="form_validation" @submit.prevent="createProvider">
                  <div class="form-group form-float">
                    <label class="fw-semibold">Name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Provider Name"
                      name="name"
                      required
                      v-model="provider_name"
                    />
                  </div>

                  <div class="form-group form-float">
                    <label class="fw-semibold">Service URI</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Service URL"
                      name="name"
                      required
                      v-model="service_uri"
                    />
                  </div>

                  <button
                    class="btn btn-raised btn-primary waves-effect"
                    type="submit"
                  >
                    <span v-if="loading">Loading...</span>
                    <span v-else>SUBMIT</span>
                  </button>
                </form>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-danger waves-effect"
                @click="showCreateModal = false"
              >
                CLOSE
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- MODAL FOR UPDATING provider -->

      <div
        class="modal"
        tabindex="-1"
        role="dialog"
        v-if="showUpdateModal"
        :style="{
          display: showUpdateModal ? 'block' : 'none',
          background: 'rgba(0, 0, 0, 0.5)',
        }"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="title" id="largeModalLabel">
                Update {{ this.provider_name ? this.provider_name : "N/A" }}
              </h4>
              <hr />
            </div>
            <div class="modal-body">
              <div class="body">
                <form id="form_validation" @submit.prevent="updateProvider">
                  <div class="form-group form-float">
                    <label class="fw-semibold">Name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Provider Name"
                      name="name"
                      v-model="provider_name"
                    />
                    <!-- required  -->
                  </div>

                  <div class="form-group form-float">
                    <label class="fw-semibold">Service URI</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Service URI"
                      name="name"
                      v-model="service_uri"
                    />
                    <!-- required -->
                  </div>

                  <button
                    class="btn btn-raised btn-primary waves-effect"
                    type="submit"
                  >
                    <span v-if="loading">Loading...</span>
                    <span v-else>UPDATE</span>
                  </button>
                </form>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-danger waves-effect"
                @click="showUpdateModal = false"
              >
                CLOSE
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- MODAL FOR DELETING A provider -->

      <div
        class="modal"
        tabindex="-1"
        role="dialog"
        v-if="showDeleteModal"
        :style="{
          display: showDeleteModal ? 'block' : 'none',
          background: 'rgba(0, 0, 0, 0.5)',
        }"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="title" id="largeModalLabel">
                Are you sure you want to Delete
                {{ this.provider_name ? this.provider_name : "N/A" }}
              </h4>
              <hr />
            </div>
            <div class="modal-body">
              <div class="body">
                <button
                  class="btn btn-raised btn-danger waves-effect"
                  type="submit"
                  @click.prevent="deleteProvider"
                >
                  <span v-if="loading">Loading...</span>
                  <span v-else>DELETE</span>
                </button>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-danger waves-effect"
                @click="showDeleteModal = false"
              >
                CLOSE
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- MODAL FOR ENABLING AND DISABLING provider -->

      <div
        class="modal"
        tabindex="-1"
        role="dialog"
        v-if="showEnablerModal"
        :style="{
          display: showEnablerModal ? 'block' : 'none',
          background: 'rgba(0, 0, 0, 0.5)',
        }"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="title" id="largeModalLabel">
                <!-- Are you sure you want to
                {{ this.status == 1 ? "Disable" : "Enable" }}
                {{ this.provider_name ? this.provider_name : "N/A" }} -->
                Update Something Specific for
                {{ this.provider_name ? this.provider_name : "N/A" }}
              </h4>
              <hr />
            </div>
            <div class="modal-body">
              <div class="body">
                <form id="form_validation" @submit.prevent="enableProvider">
                  <div class="form-group form-float">
                    <label class="fw-semibold">Attribute</label>
                    <select
                      class="form-control"
                      v-model="selectedAttribute"
                      name="attribute"
                      required
                    >
                      <option disabled value="">Select Attribute</option>

                      <option value="service_uri">service_uri</option>
                      <option value="name">name</option>
                    </select>
                  </div>

                  <div class="form-group form-float">
                    <label class="fw-semibold">Value</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Value"
                      name="name"
                      required
                      v-model="value"
                    />
                  </div>

                  <button
                    class="btn btn-raised btn-primary waves-effect"
                    type="submit"
                  >
                    <span v-if="loading">Loading...</span>
                    <span v-else>UPDATE</span>
                  </button>
                </form>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-danger waves-effect"
                @click="showEnablerModal = false"
              >
                CLOSE
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Name
              </th>

              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Service URI
              </th>

              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="loading">
              <td colspan="5" class="text-center">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </td>
            </tr>
            <tr v-else-if="providersData.length === 0">
              <td colspan="5" class="text-center">No Providers found</td>
            </tr>

            <tr v-else v-for="provider in providersData" :key="provider.id">
              <td>
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">
                      {{ provider ? provider.name : "N/A" }}
                    </h6>
                  </div>
                </div>
              </td>

              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold">{{
                  provider ? provider.service_uri : "N/A"
                }}</span>
              </td>

              <td class="align-middle text-center text-sm">
                <span
                  class="badge badge-sm text-white bg-primary cursor-pointer"
                  @click="ToggleUpdateModal(provider)"
                  >Update</span
                >

                <span
                  class="badge badge-sm text-white bg-danger cursor-pointer"
                  @click="ToggleDeleteModal(provider)"
                  >Delete</span
                >

                <span
                  class="badge badge-sm text-white cursor-pointer bg-warning"
                  @click="ToggleEnableModal(provider)"
                >
                  Update specific Column
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { useToast } from "vue-toastification";
import BaseURL from "../../services/Base";

export default {
  name: "providerTable",
  data() {
    return {
      providersData: [],
      loading: false,
      showCreateModal: false,
      provider_name: "",
      service_uri: "",
      provider_id: null,
      showUpdateModal: false,
      showDeleteModal: false,
      showEnablerModal: false,
      selectedAttribute: "",
      value: "",
    };
  },
  methods: {
    ToggleUpdateModal(provider) {
      if (provider) {
        this.provider_name = provider.name;
        this.service_uri = provider.service_uri;
        this.provider_id = provider.id;

        this.showUpdateModal = true;
      } else {
        console.error("provider data is incomplete or invalid.");

        const toast = useToast();
        toast.warning("Cannot open update modal. Incomplete provider data.");
      }
    },

    ToggleDeleteModal(provider) {
      if (provider) {
        this.provider_id = provider.id;
        this.provider_name = provider.name;

        this.showDeleteModal = true;
      } else {
        console.error("provider data is incomplete or invalid.");

        const toast = useToast();
        toast.warning("Cannot open Delete modal. Incomplete provider data.");
      }
    },
    ToggleEnableModal(provider) {
      if (provider) {
        this.provider_id = provider.id;
        this.provider_name = provider.name;

        this.showEnablerModal = true;
      } else {
        console.error("provider data is incomplete or invalid.");

        const toast = useToast();
        toast.warning(
          "Cannot open Enable/Disable modal. Incomplete provider data."
        );
      }
    },

    async FetchProviders() {
      const toast = useToast();

      try {
        this.loading = true;
        const token = Cookies.get("token");
        console.log("token", token);

        const response = await BaseURL.get("provider", {
          headers: {
            "Content-Type": "application/json",
            "api-key": token,
            //  Authorization: `Bearer ${token}`
          },
        });

        console.log("provider", response);

        if (response.status == 200) {
          this.providersData = response.data;
        }
      } catch (err) {
        console.log(err);
        if (err.status == 401) {
          toast.error(err.response.data.message);
          setTimeout(() => {
            this.$router.push("/signin");
          }, 1000);
        } else if (err.status == 403) {
          toast.error(err.response.data.message);
        } else if (err.status == 404) {
          toast.error(err.response.data.error_message);
        }
      } finally {
        this.loading = false;
      }
    },
    async createProvider() {
      const toast = useToast();

      if (!this.provider_name || !this.service_uri) {
        toast.warning("Please Fill all details");
        return;
      }

      try {
        this.loading = true;
        const token = Cookies.get("token");

        const data = {
          name: this.provider_name,

          service_uri: this.service_uri,
        };

        const response = await BaseURL.post("provider", data, {
          headers: {
            "Content-Type": "application/json",
            "api-key": token,
            //  Authorization: `Bearer ${token}`
          },
        });

        console.log("provider created", response);

        if (response.status == 201) {
          toast.success("Provider Created" || response.data.data);
          this.FetchProviders();
          // this.showCreateModal = false
          return;
        }
      } catch (err) {
        console.log(err);
        if (err.status == 401) {
          toast.error(err.response.data.error_message);
          setTimeout(() => {
            this.$router.push("/signin");
          }, 1000);
        } else if (err.status == 400) {
          toast.error(err.response.data.error_message);
        } else if (err.status == 417) {
          toast.error(err.response.data.error_message);
        } else if (err.status == 422) {
          toast.error(err.response.data.error_message);
        } else if (err.status == 423) {
          toast.error(err.response.data.error_message);
        }
      } finally {
        this.loading = false;
        this.showCreateModal = false;
      }
    },

    async updateProvider() {
      const toast = useToast();

      try {
        this.loading = true;
        const token = Cookies.get("token");

        const data = {
          name: this.provider_name,
          service_uri: this.service_uri,
        };

        const response = await BaseURL.put(
          `provider/${this.provider_id}`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              "api-key": token,
              //  Authorization: `Bearer ${token}`
            },
          }
        );

        console.log("provider Updated", response);

        if (response.status == 201) {
          toast.success("provider Updated" || response.data.data);
          this.FetchProviders();
          this.showUpdateModal = false;
          return;
        }
      } catch (err) {
        console.log(err);
        if (err.status == 401) {
          toast.error(err.response.data.error_message);
          setTimeout(() => {
            this.$router.push("/signin");
          }, 1000);
        } else if (err.status == 400) {
          toast.error(err.response.data.error_message);
        } else if (err.status == 417) {
          toast.error(err.response.data.error_message);
        } else if (err.status == 422) {
          toast.error(err.response.data.error_message);
        } else if (err.status == 423) {
          toast.error(err.response.data.error_message);
        }
      } finally {
        this.loading = false;
        this.showUpdateModal = false;
      }
    },

    async deleteProvider() {
      const toast = useToast();

      try {
        this.loading = true;
        const token = Cookies.get("token");

        const response = await BaseURL.delete(`provider/${this.provider_id}`, {
          headers: {
            "Content-Type": "application/json",
            "api-key": token,
            //  Authorization: `Bearer ${token}`
          },
        });

        console.log("provider deleted", response);

        if (response.status == 201) {
          toast.success(response.data.data);
          // 'provider Updated' ||
          this.FetchProviders();
          this.showDeleteModal = false;
          return;
        }
      } catch (err) {
        console.log(err);
        if (err.status == 401) {
          toast.error(err.response.data.message);
          setTimeout(() => {
            this.$router.push("/signin");
          }, 1000);
        } else if (err.status == 400) {
          toast.error(err.response.data.message);
        } else if (err.status == 417) {
          toast.error(err.response.data.message);
        } else if (err.status == 422) {
          toast.error(err.response.data.message);
        } else if (err.status == 423) {
          toast.error(err.response.data.message);
        }
      } finally {
        this.loading = false;
        this.showDeleteModal = false;
      }
    },

    async enableProvider() {
      const toast = useToast();

      if (!this.selectedAttribute || !this.value) {
        toast.warning("Please Fill all Fields");
        return;
      }

      try {
        this.loading = true;
        const token = Cookies.get("token");
        const data = {
          field_name: this.selectedAttribute,
          value: this.value,
        };

        const response = await BaseURL.patch(
          `provider/${this.provider_id}`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              "api-key": token,
              //  Authorization: `Bearer ${token}`
            },
          }
        );

        console.log("provider value updated", response);

        if (response.status == 201) {
          toast.success(response.data.data);

          this.FetchProviders();
          this.showEnablerModal = false;
          return;
        }
      } catch (err) {
        console.log(err);
        if (err.status == 401) {
          toast.error(err.response.data.message);
          setTimeout(() => {
            this.$router.push("/signin");
          }, 1000);
        } else if (err.status == 400) {
          toast.error(err.response.data.message);
        } else if (err.status == 417) {
          toast.error(err.response.data.message);
        } else if (err.status == 422) {
          toast.error(err.response.data.message);
        } else if (err.status == 423) {
          toast.error(err.response.data.message);
        }
      } finally {
        this.loading = false;
        this.showEnablerModal = false;
      }
    },
  },
  mounted() {
    this.FetchProviders();
  },
};
</script>
