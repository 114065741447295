<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import Breadcrumbs from "../Breadcrumbs.vue";

export default {
  name: "Navbar",
  components: {
    Breadcrumbs,
  },
  props: {
    title: {
      type: String,
      default: "Categories",
    },
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const showMenu = ref(false);

    const isRTL = computed(() => store.state.isRTL);

    const currentRouteName = computed(() => route.name);

    const currentDirectory = computed(() => {
      const dir = route.path.split("/")[1];
      return dir.charAt(0).toUpperCase() + dir.slice(1);
    });

    const minimizeSidebar = () => store.commit("sidebarMinimize");

    const closeMenu = () => {
      setTimeout(() => {
        showMenu.value = false;
      }, 100);
    };

    return {
      showMenu,
      isRTL,
      currentRouteName,
      currentDirectory,
      minimizeSidebar,
      closeMenu,
    };
  },
};
</script>

<template>
  <nav
    class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl"
    :class="isRTL ? 'top-0 position-sticky z-index-sticky' : ''"
    v-bind="$attrs"
    id="navbarBlur"
    data-scroll="true"
  >
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs
        :current-page="currentRouteName"
        :current-directory="currentDirectory"
      />

      <div
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        :class="isRTL ? 'px-0' : 'me-sm-4'"
        id="navbar"
      >
        <div
          class="pe-md-3 d-flex align-items-center"
          :class="isRTL ? 'me-md-auto' : 'ms-md-auto'"
        ></div>
        <ul class="navbar-nav justify-content-end">
          <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
            <a
              href="#"
              @click="minimizeSidebar"
              class="p-0 nav-link text-white"
              id="iconNavbarSidenav"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
                <i class="sidenav-toggler-line bg-white"></i>
              </div>
            </a>
          </li>
          <li class="px-3 nav-item d-flex align-items-center">
            <router-link to="/profile" class="p-0 nav-link text-white">
              <i class="cursor-pointer fa fa-cog fixed-plugin-button-nav"></i>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
