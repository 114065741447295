import IdentityService from "../services/Auth";
export default {
  namespaced: true,
  state: {
    registrationStatus: "",
    user: null,
    loading: false,
    error: null,
  },
  actions: {
    async register({ commit }, payload) {
      commit("setLoading", true);
      commit("clearError");

      try {
        const response = await IdentityService.post("/signup", payload);
        console.log("registration data", response);

        if(response.status == 202 || response.status == 201){
          commit("setUser", response);

          commit("setRegistrationStatus", "success");
        }

      } catch (err) {
        console.log(err);
        commit("setRegistrationStatus", "failed");

        if (err.response) {
          const { status, data } = err.response;
          let errorMessage = data.error_message || "An error occurred";

          switch (status) {
            case 400:
              errorMessage = data.error_message || "Invalid Inputs";
              break;

            case 422:
              errorMessage = data.error_message || "Unprocessable Entity";
              break;

            case 500:
              errorMessage = data.error_message || "Internal Server Error";
              break;

            default:
              errorMessage = data.error_message || "An unknown error occurred";
              break;
          }
          commit("setError", errorMessage);
        } else {
          commit("setError", "Network Error. Check Your Connection");
        }
      } finally {
        commit("setLoading", false);
      }
    },
  },
  mutations: {
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
    setUser(state, user) {
      state.user = user;
    },
    setRegistrationStatus(state, status) {
      state.registrationStatus = status;
    },
    setError(state, error) {
      state.error = error;
    },
    clearError(state) {
      state.error = null;
    },
  },
  getters: {
    isLoading: (state) => state.loading,
    getUser: (state) => state.user,
    registrationStatus: (state) => state.registrationStatus,
    errorMessage: (state) => state.errorMessage,
  },
};
